import React from "react";
import PropTypes from "prop-types";

import styles from "./Card.module.css";

const Card = ({ children, type, ariaLive, ariaRelevant, ...other }) => {
  const classType = {
    main: "main",
    half: "half",
    container: "container",
    aside: "aside",
    bottom: "bottom",
    mainListingPage: "mainListingPage",
    getInTouch: "getInTouch",
    homepageTopicWrapper: "homepageTopicWrapper",
  };

  const classes = [styles.card, styles[classType[type]]];

  return (
    <div
      {...other}
      className={classes.join(" ")}
      aria-live={ariaLive || null}
      aria-relevant={ariaRelevant || null}
    >
      {children}
    </div>
  );
};

export default Card;

Card.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  ariaLive: PropTypes.string,
  ariaRelevant: PropTypes.string,
};
