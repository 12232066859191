import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Hero.module.css";
import debounce from "src/utilities/debounce";
import SearchBar from "src/app/components/SearchBar/SearchBar";

const Hero = ({
  text = "",
  mediumImage = "",
  largeImage = "",
  isCaps = true,
  placeholder = "",
  type = "",
}) => {
  const [windowSize, setWindowSize] = useState(null);

  const imageSrc = (width) => {
    if (!width) return null;
    if (width >= 720) return `url(${largeImage})`;
    return `url(${mediumImage})`;
  };

  const imageHandler = () => {
    debounce(setWindowSize(window.innerWidth), 100);
  };

  useEffect(() => {
    imageHandler();
    window.addEventListener("resize", imageHandler);

    return () => {
      window.removeEventListener("resize", imageHandler);
    };
  }, []);

  const heroClass = classNames(
    `${styles.heroContainer} ${
      placeholder ? `${styles.heroHomepage} ${type}` : `${styles[type]}`
    } ${imageSrc(windowSize) === "url()" ? `${styles.noImage}` : ""}`
  );

  return (
    <div
      className={heroClass}
      style={{ backgroundImage: imageSrc(windowSize) }}
    >
      <h1
        className={styles.heroText}
        style={isCaps ? { textTransform: "uppercase" } : {}}
        id={placeholder ? "searchHeader" : "mainContent"}
      >
        {text}
      </h1>
      {placeholder && (
        <SearchBar
          placeholder={placeholder["placeholder-text"]}
          styles={styles}
          displayDropdown={true}
          type="homepage"
        />
      )}
    </div>
  );
};

export default Hero;

Hero.propTypes = {
  text: PropTypes.string.isRequired,
  mediumImage: PropTypes.string,
  largeImage: PropTypes.string,
  isCaps: PropTypes.bool,
  placeholder: PropTypes.object,
  type: PropTypes.string,
};
