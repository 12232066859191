import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { AnchorContent } from "../CardAnchorContent";

const CardAnchor = ({
  link,
  target,
  icon,
  disabled,
  onClick,
  dataValue,
  isFocused,
  styles,
  children,
}) => {
  const anchorRef = useRef(null);

  useEffect(() => {
    if (isFocused && anchorRef) {
      anchorRef.current.focus();
    }
  }, [isFocused]);

  const Element = disabled ? "div" : "a";

  return (
    <Element
      href={link}
      target={target}
      ref={anchorRef}
      onClick={!disabled ? onClick : undefined}
      data-value={dataValue}
      className={`${disabled ? styles.disabled + " " : ""}${styles.anchor}`}
    >
      <AnchorContent icon={icon} styles={styles}>
        {children}
      </AnchorContent>
    </Element>
  );
};

CardAnchor.propTypes = {
  link: PropTypes.string,
  target: PropTypes.string,
  title: PropTypes.object,
  icon: PropTypes.func,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  dataValue: PropTypes.string,
  isFocused: PropTypes.bool,
  styles: PropTypes.object,
  children: PropTypes.object,
};

export default CardAnchor;
