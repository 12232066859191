import React, { useEffect, useState } from "react";
import styles from "./ServiceMessages.module.css";
import Accordion from "src/app/components/ContentElements/Accordion/Accordion";
import getConfig from "src/analyticsProvider/index";
import { trackPageInteraction } from "asos-contentweb-analytics-middleware";
import fetchServiceMessages from "src/utilities/fetchServiceMessages";
import transformParameters from "src/utilities/transformParameters";

const ServiceMessages = () => {
  const [serviceMessages, setServiceMessages] = useState([]);

  useEffect(() => {
    async function initialiseFetchServiceMessages() {
      const params = transformParameters();
      const storeCode = window.asos.webContext.getStoreId() || "";
      const language = window.asos.webContext.getBrowseLanguage() || "";

      fetchServiceMessages(storeCode, language, params.client).then((res) => {
        if (res && res.serviceMessages) {
          const serviceMessages = res.serviceMessages;
          setServiceMessages(serviceMessages);
          const serviceMsgTrack = getConfig("service-messages");
          trackPageInteraction(serviceMsgTrack);
        }
      });
    }

    initialiseFetchServiceMessages();
  }, []);

  if (serviceMessages && serviceMessages.length === 0) {
    return null;
  }

  return (
    <ul className={styles.serviceMessage}>
      {serviceMessages &&
        serviceMessages.map((item, i) => (
          <Accordion
            data={item}
            key={`service-message-${i}`}
            index={i}
            type="serviceMsg"
            length={(serviceMessages && serviceMessages.length) || ""}
          />
        ))}
    </ul>
  );
};

export default ServiceMessages;
