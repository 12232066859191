import React from "react";
import PropTypes from "prop-types";
import Card from "../Card/Card";
import { CardSingleItem } from "../ContentElements/CardSingleItem/CardSingleItem";
import RelatedFaqWrapper from "./RelatedFaqWrapper/RelatedFaqWrapper";
import HomepageHeader from "../HomepageHeader/HomepageHeader";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import styles from "./HelpTopicsHomepage.module.css";
import iconsMap from "../../../assets/iconsMap.js";

export default function HelpTopicsHomepage({ topics }) {
  if (!topics || topics.length <= 0) return null;

  const topicItems = topics.filter((item) => item.type === "topic");
  const header = topics.filter((item) => item.type === "header")[0];
  const viewMoreLink = header?.button?.link ?? "View All";

  return (
    <ErrorBoundary>
      {header && <HomepageHeader text={header.text} isCaps={header.isCaps} />}
      <div className={styles.topicContainer}>
        {topicItems.map((topicItem, i) => (
          <Card type="homepageTopicWrapper" key={`${topicItem.text}-${i}`}>
            <CardSingleItem
              icon={iconsMap(topicItem.iconId && topicItem.iconId.text)}
              link={
                topicItem.link &&
                topicItem.link.link &&
                topicItem.link.link.href
              }
              text={topicItem.text}
            />
            <RelatedFaqWrapper topic={topicItem} viewMoreLink={viewMoreLink} />
          </Card>
        ))}
      </div>
    </ErrorBoundary>
  );
}

HelpTopicsHomepage.propTypes = {
  topics: PropTypes.array.isRequired,
};
