import React, { useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import SearchDropdown from "src/app/components/SearchDropdown/SearchDropdown";
import iconsMap from "src/assets/iconsMap";
import searchTermCleanser from "src/app/utilities/searchTermCleanser";
import getRecentSearches from "src/app/utilities/getRecentSearches";
import setRecentSearches from "src/app/utilities/setRecentSearches";
import getSearchUrl from "src/app/utilities/getSearchUrl";
import StoreDataContext from "src/app/context/store-data";

import defaultStyles from "./SearchBar.module.css";

const SearchBar = ({
  styles = defaultStyles,
  placeholder = "",
  displayDropdown,
  type,
}) => {
  const { currentUrl } = useContext(StoreDataContext);
  const [searchInput, setSearchInput] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const inputFieldRef = useRef(null);
  const formRef = useRef(null);
  const searchItems = getRecentSearches();
  const SearchIcon = iconsMap("Search");
  const submitUrl = getSearchUrl(currentUrl);

  const handleChange = (event) => {
    const cleansedString = searchTermCleanser(event.target.value);
    setSearchInput(cleansedString);
  };

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (searchInput) {
      const cleansedString = searchTermCleanser(searchInput);
      handleSaveOnSubmit(cleansedString);
      inputFieldRef.current.value = cleansedString;
      formRef.current.submit();
    }
  };

  const handleDropdownClick = (event) => {
    setSearchInput(event.currentTarget.value);
    handleSubmit();
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 40) {
      const el = document.getElementById(`formButton-0`);

      if (el) {
        return el.focus();
      }
    }
  };

  const handleSaveOnSubmit = (searchText) => {
    const searchValue = searchText.length && searchText.trim();
    if (!searchValue) return;

    setRecentSearches(searchItems, searchValue);
  };

  const handleFocus = () => {
    setDropdownVisible(true);
  };

  const focusInput = () => {
    inputFieldRef.current.focus();
  };

  const closeSearchesDropdown = () => {
    setDropdownVisible(false);
  };

  return (
    <div className={styles.searchBar}>
      <form
        action={submitUrl}
        onSubmit={handleSubmit}
        method="get"
        className={styles.form}
        ref={formRef}
      >
        <input
          type="search"
          name="q"
          ref={inputFieldRef}
          aria-label={placeholder}
          placeholder={placeholder}
          autoComplete="off"
          maxLength="255"
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
        />
        <button
          type="submit"
          aria-label={placeholder}
          disabled={searchInput.length === 0}
          className={
            searchInput.length > 0
              ? `${styles.active} ${styles.submitButton}`
              : `${styles.submitButton}`
          }
        >
          <SearchIcon />
        </button>
        {dropdownVisible && displayDropdown && (
          <SearchDropdown
            handleSubmit={handleDropdownClick}
            focusInput={focusInput}
            closeSearchesDropdown={closeSearchesDropdown}
            searchItems={searchItems}
            type={type}
          />
        )}
      </form>
    </div>
  );
};

SearchBar.defaultProps = {
  styles: defaultStyles,
};

export default SearchBar;

SearchBar.propTypes = {
  placeholder: PropTypes.string.isRequired,
  styles: PropTypes.object,
  displayDropdown: PropTypes.bool.isRequired,
  type: PropTypes.string,
};
