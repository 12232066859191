import React, { useState } from "react";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { trackPageInteraction } from "asos-contentweb-analytics-middleware";
import CardHeader from "src/app/components/ContentElements/CardHeader/CardHeader";
import Button from "src/app/components/ContentElements/Button/Button";
import iconsMap from "src/assets/iconsMap.js";
import getConfig from "src/analyticsProvider/index";
import styles from "./ViewRelatedQuestions.module.css";
import { CardListItem } from "../ContentElements/CardListItem/CardListItem";

const ViewRelatedQuestions = ({
  analytics: { articleName = "", category = "" } = {},
  button: { buttonText, buttonIsCaps } = {},
  data: { header = {}, articles },
  page = "listingPage",
  showButton = true,
  visibleItems: initialVisibleItems = 4,
}) => {
  const [visibleItems, setVisibleItems] = useState(initialVisibleItems);
  const [pageCounter, setPageCounter] = useState(1);

  const icon = "Related Article";
  const button = {
    text: buttonText,
    link: {
      href: "#",
      target: "",
    },
    isCaps: buttonIsCaps,
  };

  const onClickHandler = (e) => {
    e.preventDefault();
    setVisibleItems(visibleItems + initialVisibleItems);
    setPageCounter(pageCounter + 1);

    const loadMoreConfig = getConfig("related-questions-load-more", {
      page: page,
      pageNumber: pageCounter,
      articleName: articleName.toLowerCase(),
      category: category.toLowerCase(),
    });
    trackPageInteraction(loadMoreConfig);
  };

  if (!articles) return null;

  return (
    <div className={page === "homepage" ? styles.popularQuestions : null}>
      {page !== "homepage" && (
        <CardHeader
          text={header.text}
          isCaps={header.isCaps}
          id="viewRelatedQuestions"
        />
      )}
      <ul>
        <TransitionGroup component={null}>
          {articles.slice(0, visibleItems).map((article, i) => (
            <CSSTransition
              key={`${article.text}-${i}`}
              timeout={500}
              classNames="global_item"
            >
              <CardListItem
                key={`${article.text}-${i}`}
                text={article.text}
                link={article.link.href}
                icon={iconsMap(icon)}
                iconPosition="right"
                ariaPosInSet={i + 1}
                ariaSetSize={articles.length}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </ul>
      {showButton && visibleItems < articles.length && (
        <div className={styles.buttonContainer}>
          <Button data={button} role="button" onClick={onClickHandler} />
        </div>
      )}
    </div>
  );
};

ViewRelatedQuestions.propTypes = {
  analytics: PropTypes.shape({
    articleName: PropTypes.string,
    category: PropTypes.string,
  }),
  button: PropTypes.object,
  data: PropTypes.shape({
    header: PropTypes.shape({
      text: PropTypes.string.isRequired,
      isCaps: PropTypes.bool,
    }),
    articles: PropTypes.array,
  }),
  page: PropTypes.string,
  showButton: PropTypes.bool,
  visibleItems: PropTypes.number,
};

export default ViewRelatedQuestions;
