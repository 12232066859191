import { configureRequest } from "@asosteam/asos-web-request";
import noticeError from "src/app/utilities/noticeError";
import logger from "src/app/utilities/logger";

const request = configureRequest({
  retries: 2,
  defaultTimeout: 10000,
  retryDelay: () => 100,
  logger,
  client: {
    name: "asos-contentweb-customer-care-help-pages",
    version: "1.0.0",
    platform: "CPT",
  },
});

export default async function fetchServiceMessages(
  storeCode,
  language,
  client
) {
  let response = null;
  try {
    response = await request.get({
      url: `/api/care/helpcontact/v1/servicemessages?store=${storeCode}&lang=${language}&client=${client}`,
    });
  } catch (err) {
    noticeError(err);
  }

  if (response) return response.data;

  return null;
}
