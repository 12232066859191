import React, { useState } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import AnimateHeight from "react-animate-height";
import classNames from "classnames";
import iconsMap from "src/assets/iconsMap.js";
import styles from "./Accordion.module.css";

const Accordion = ({ data, index, length, type = "" }) => {
  const initialStateOpened = type === "serviceMsg" && length < 3 && index === 0;
  const [isOpen, setIsOpen] = useState(initialStateOpened);
  const [height, setHeight] = useState(initialStateOpened ? "auto" : 0);

  const onClickHandler = () => {
    setHeight(height === 0 ? "auto" : 0);
    setIsOpen(!isOpen);
  };

  if (!data) {
    return null;
  }

  const iconName = isOpen ? "minus" : "plus";
  const Icon = iconsMap(iconName);
  const iconClass = classNames(styles.icon, {
    [`${styles.iconMinus}`]: isOpen,
  });
  const liClass = classNames(styles.accordionSection, {
    [`${styles[type]}`]: type,
  });
  const sectionContentClass = classNames(styles.accordionSectionContent, {
    [`${styles.isOpen}`]: isOpen,
  });

  return (
    <li className={liClass}>
      <button
        className={styles.accordionHeader}
        aria-controls={`accordion-section-${index + 1}`}
        aria-expanded={isOpen}
        id={`accordion-section-control-${index + 1}`}
        onClick={onClickHandler}
      >
        {data.headerText || parse("&nbsp;")}
        <div className={styles.iconWrapper}>
          <div className={iconClass} data-testid={iconName}>
            <Icon />
          </div>
        </div>
      </button>
      <AnimateHeight duration={300} height={height}>
        <div
          className={sectionContentClass}
          id={`accordion-section-${index + 1}`}
          aria-hidden={!isOpen}
        >
          <p className={styles.accordionText}>
            {parse(data.messageText || "")}
          </p>
        </div>
      </AnimateHeight>
    </li>
  );
};

export default Accordion;

Accordion.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  length: PropTypes.number,
  type: PropTypes.string,
};
