import React from "react";
import PropTypes from "prop-types";
import CardAnchor from "../CardAnchor";
import styles from "./CardSingleItem.module.css";

export const CardSingleItem = ({
  link,
  target = null,
  icon: Icon,
  text,
  ...other
}) => {
  if (!link) return null;
  return (
    <div className={styles.homepageTopicItem} {...other}>
      <CardAnchor link={link} target={target} icon={Icon} styles={styles}>
        <h3 className={styles.textWrapper}>{text}</h3>
      </CardAnchor>
    </div>
  );
};

CardSingleItem.propTypes = {
  link: PropTypes.string,
  target: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.func,
  text: PropTypes.string,
};

CardSingleItem.displayName = "CardSingleItem";
