const transformParameters = () => {
  const webContextPlatform = window.asos
    ? window.asos.webContext.getPlatform()
    : "desktop";

  return {
    client: webContextPlatform === "desktop" ? "W" : "M",
  };
};

export default transformParameters;
