import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import debounce from "src/utilities/debounce";
import styles from "./SearchDropdown.module.css";

const SearchDropdown = ({
  closeSearchesDropdown,
  focusInput,
  handleSubmit,
  searchItems: recentSearches,
  type,
}) => {
  const [windowSize, setWindowSize] = useState(null);
  const buttonRef = useRef([]);

  const handleDropdownDisplay = () => {
    debounce(setWindowSize(window.innerWidth), 100);
  };

  useEffect(() => {
    handleDropdownDisplay();
    window.addEventListener("resize", handleDropdownDisplay);

    return function cleanup() {
      window.removeEventListener("resize", handleDropdownDisplay);
    };
  }, []);

  const handleKeyDown = (event) => {
    const currentItem = recentSearches.indexOf(event.target.value);

    if (event.keyCode === 9 && !event.shiftKey) {
      const nextEl = buttonRef.current[currentItem + 1];

      if (!nextEl) {
        closeSearchesDropdown();
      }
    }

    if (event.keyCode === 40) {
      const nextEl = buttonRef.current[currentItem + 1];

      if (nextEl) {
        return nextEl.focus();
      }

      return focusInput();
    }

    if (event.keyCode === 38) {
      const prevEl = buttonRef.current[currentItem - 1];

      if (prevEl) {
        return prevEl.focus();
      }

      return focusInput();
    }
  };

  if (!recentSearches.length || windowSize === null || windowSize < 720)
    return null;

  const className = classNames(styles.searchDropdown, {
    [`${styles.searchDropdownHomePage}`]: type === "homepage",
  });

  const searches = recentSearches.map((searchItem, i) => {
    return (
      <li key={`formButton-${i}`}>
        <button
          id={`formButton-${i}`}
          role="link"
          value={searchItem}
          onKeyDown={handleKeyDown}
          onClick={handleSubmit}
          ref={(el) => (buttonRef.current[i] = el)}
        >
          <span>{searchItem}</span>
        </button>
      </li>
    );
  });

  return (
    <>
      <div className={className} aria-live="polite">
        <ul>{searches}</ul>
      </div>
      <div
        aria-hidden="true"
        className={styles.recentSearchesOverlay}
        onClick={closeSearchesDropdown}
      />
    </>
  );
};

export default SearchDropdown;

SearchDropdown.propTypes = {
  closeSearchesDropdown: PropTypes.func.isRequired,
  focusInput: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  searchItems: PropTypes.array,
  type: PropTypes.string,
};
