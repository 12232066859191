/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Main from "src/app/components/Main/Main";
import HelpTopicsHomepage from "src/app/components/HelpTopicsHomepage/HelpTopicsHomepage";
import HomepageHeader from "src/app/components/HomepageHeader/HomepageHeader";
import Card from "src/app/components/Card/Card";
import GetInTouch from "src/app/components/GetInTouch/GetInTouch";
import PopularQuestions from "src/app/components/ViewRelatedQuestions/ViewRelatedQuestions";
import ErrorBoundary from "src/app/components/ErrorBoundary/ErrorBoundary";
import Hero from "src/app/components/Hero/Hero";
import ServiceMessages from "src/app/components/ServiceMessages/ServiceMessages";
import styles from "./Homepage.module.css";

// eslint-disable-next-line complexity
export default function Homepage() {
  const content = useSelector((state) => state.content);
  const { previewing } = useSelector((state) => state.previewing);
  const searchBox = content?.main?.body?.["search-box"]?.elements[0] ?? false;
  const serviceMessages = content?.body?.serviceMessages ?? false;
  const popularArticles =
    content?.main?.body?.["popular-articles"]?.elements[0] ?? false;
  const getInTouch =
    content?.main?.body?.["get-in-touch"]?.elements[0] ?? false;
  const topics = content?.main?.body?.topics?.elements ?? false;

  if (
    !searchBox &&
    !serviceMessages &&
    !popularArticles &&
    !getInTouch &&
    !topics
  )
    return null;

  return (
    <ErrorBoundary>
      <Main fullwidth={true} flex={true}>
        {searchBox && (
          <Card type="container" style={{ marginTop: 0 }}>
            <Hero
              text={searchBox?.homepageTitle?.text}
              isCaps={searchBox?.homepageTitle?.isCaps}
              mediumImage={
                searchBox?.smallImagePlaceholder?.["smallImagePlaceholder-text"]
                  ?.src
              }
              largeImage={
                searchBox?.mediumToLargeImagePlaceholder?.[
                  "mediumToLargeImagePlaceholder-text"
                ]?.src
              }
              placeholder={searchBox?.placeholder}
            />
          </Card>
        )}
        {!previewing && (
          <div className={styles.serviceMessageContainer}>
            <ServiceMessages />
          </div>
        )}
        {topics && (
          <div className={styles.helpTopicsOuterWrapper}>
            <HelpTopicsHomepage topics={topics} />
          </div>
        )}
        {popularArticles && (
          <Card type="container">
            <HomepageHeader
              text={popularArticles.header && popularArticles.header.text}
              isCaps={popularArticles.header && popularArticles.header.isCaps}
              analytics={content.analytics}
            />
            <Card type="half">
              <PopularQuestions
                data={popularArticles}
                page="homepage"
                button={popularArticles["faq-list"]}
              />
            </Card>
          </Card>
        )}
        {getInTouch && (
          <Card type="container">
            <HomepageHeader {...getInTouch.header} />
            <Card type="half">
              <GetInTouch data={getInTouch} page="homepage" />
            </Card>
          </Card>
        )}
      </Main>
    </ErrorBoundary>
  );
}

Homepage.defaultName = "Homepage";

Homepage.propTypes = {
  content: PropTypes.object,
  storeData: PropTypes.object,
};
