import React from "react";
import PropTypes from "prop-types";

import styles from "./RelatedFaqWrapper.module.css";

const RelatedFaqWrapper = ({ topic, viewMoreLink }) => {
  return (
    <div className={styles.relatedFaqWrapper}>
      <ul>
        {topic.faqs &&
          topic.faqs.slice(0, 3).map((linkedFaq, i) => {
            const { link: { href, target } = {}, text } = linkedFaq;

            if (!href || !text) return;

            return (
              <li key={`${text}-${i}`}>
                <a
                  href={href}
                  target={target}
                  className={styles.relatedFaqItem}
                >
                  {text}
                </a>
              </li>
            );
          })}
      </ul>
      {topic.link && topic.link.link && (
        <a
          href={topic.link.link.href}
          className={styles.viewMoreLink}
          target={topic.link.link.target}
        >
          {viewMoreLink}
        </a>
      )}
    </div>
  );
};

export default RelatedFaqWrapper;

RelatedFaqWrapper.propTypes = {
  topic: PropTypes.shape({
    faqs: PropTypes.array.isRequired,
    link: PropTypes.shape({
      link: PropTypes.shape({
        href: PropTypes.string,
        target: PropTypes.string,
      }),
      text: PropTypes.string,
    }),
  }),
  viewMoreLink: PropTypes.string,
};
