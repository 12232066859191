import React from "react";
import PropTypes from "prop-types";

export const AnchorContent = ({ icon: Icon, styles, children }) => {
  return (
    <>
      {Icon && (
        <div className={styles.imageWrapper}>
          <Icon />
        </div>
      )}
      {children}
    </>
  );
};

AnchorContent.propTypes = {
  icon: PropTypes.func,
  title: PropTypes.object,
  styles: PropTypes.object,
  children: PropTypes.object,
};
