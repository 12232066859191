import React from "react";
import PropTypes from "prop-types";

import defaultStyles from "./CardHeader.module.css";

const CardHeader = ({ text, isCaps, styles = defaultStyles, ...other }) => {
  return (
    text && (
      <h2
        className={styles.header}
        {...other}
        style={isCaps ? { textTransform: "uppercase" } : null}
      >
        {text}
      </h2>
    )
  );
};

export default CardHeader;

CardHeader.propTypes = {
  text: PropTypes.string.isRequired,
  isCaps: PropTypes.bool,
  styles: PropTypes.object,
};
