export default function setRecentSearches(searchItems, searchValue) {
  if (window.localStorage) {
    if (!searchItems.includes(searchValue)) {
      const newSearchItems = [...searchItems];

      if (newSearchItems.length === 3) {
        newSearchItems.pop();
      }

      newSearchItems.unshift(searchValue);

      window.localStorage.setItem("cc:search", JSON.stringify(newSearchItems));
    }
  }
}
