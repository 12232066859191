import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import CardAnchor from "../CardAnchor";

import styles from "./CardListItem.module.css";

export const CardListItem = ({
  text,
  icon: Icon,
  link,
  target = null,
  iconPosition = "left",
  ariaPosInSet,
  ariaSetSize,
  isFocused = false,
  onClick,
  styleClass = null,
  disabled = false,
  ...other
}) => {
  return (
    <li
      key={text}
      className={classNames({
        [`${styles.topicItem}`]: true,
        [`${styles.iconRight}`]: iconPosition === "right",
        [`${styles.contactList}`]: styleClass !== null,
      })}
      aria-setsize={ariaSetSize}
      aria-posinset={ariaPosInSet}
      {...other}
    >
      <CardAnchor
        link={link}
        onClick={onClick}
        dataValue={text}
        target={target}
        isFocused={isFocused}
        icon={Icon}
        disabled={disabled}
        styles={styles}
      >
        <div className={styles.textWrapper}>{text}</div>
      </CardAnchor>
    </li>
  );
};

CardListItem.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.func,
  link: PropTypes.string.isRequired,
  target: PropTypes.string,
  iconPosition: PropTypes.string,
  ariaPosInSet: PropTypes.number,
  ariaSetSize: PropTypes.number,
  isFocused: PropTypes.bool,
  onClick: PropTypes.func,
  styleClass: PropTypes.string,
  disabled: PropTypes.bool,
};

CardListItem.displayName = "CardListItem";
