import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";

import styles from "./GetInTouch.module.css";
import CardHeader from "src/app/components/ContentElements/CardHeader/CardHeader";
import Button from "src/app/components/ContentElements/Button/Button";
import debounce from "src/utilities/debounce";

const GetInTouch = ({ page = "aside", data }) => {
  const {
    header = {},
    button,
    "image-medium": { image: { src: mediumImage } = {} } = {},
    "image-large": { image: { src: largeImage } = {} } = {},
  } = data;

  const [windowSize, setWindowSize] = useState(0);

  const handleBgImage = () => {
    debounce(setWindowSize(window.innerWidth), 100);
  };

  useEffect(() => {
    handleBgImage();
    window.addEventListener("resize", handleBgImage);

    return function cleanup() {
      window.removeEventListener("resize", handleBgImage);
    };
  }, []);

  return (
    <>
      {windowSize > 640 && (
        <LazyLoad height={184} once unmountIfInvisible>
          <div
            aria-hidden="true"
            className={`${styles.bgImage} ${
              page === "homepage" ? styles.homepage : ""
            }`}
            style={{
              backgroundImage: `url(${
                page === "homepage" && windowSize < 840 && windowSize > 720
                  ? `${largeImage}`
                  : `${mediumImage}`
              })`,
            }}
            data-testid="getintouch-bg"
          />
        </LazyLoad>
      )}
      {page !== "homepage" && (
        <CardHeader
          text={header.text}
          isCaps={header.isCaps}
          id="getInTouch"
          styles={styles}
        />
      )}
      <div className={styles.buttonContainer}>
        <Button data={button} />
      </div>
    </>
  );
};

export default GetInTouch;

GetInTouch.propTypes = {
  data: PropTypes.shape({
    "header": PropTypes.object.isRequired,
    "image-medium": PropTypes.object.isRequired,
    "image-large": PropTypes.object.isRequired,
    "button": PropTypes.object.isRequired,
  }),
  page: PropTypes.string,
};
