import React from "react";
import PropTypes from "prop-types";

import styles from "./HomepageHeader.module.css";

const HomepageHeader = ({ text, isCaps, ...other }) => {
  if (!text) return null;

  return (
    <h2
      className={styles.header}
      {...other}
      style={isCaps ? { textTransform: "uppercase" } : null}
    >
      {text}
    </h2>
  );
};

export default HomepageHeader;

HomepageHeader.propTypes = {
  text: PropTypes.string.isRequired,
  isCaps: PropTypes.bool,
  styles: PropTypes.object,
};
